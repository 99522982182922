<script setup>
import Layout from "../../layouts/main.vue";
import MethodService from "../../service/MethodService";
import { FormInstance } from "element-plus";
import mushroom from "cem-primary-api";
import { onMounted, reactive, ref, watch } from "vue";
import DataForm from "./dataFormHanhChinh";
import { DVHCStore } from "../../stores/getDVHC";
import DataService from "../../service/DataService";

const valueTinh = ref([]);
const valueHuyen = ref([]);
const dvhc = DVHCStore();
const ruleFormRef = ref(FormInstance);
const validForm = reactive({ value: DataForm.tableRules.dataSearch.valid });
const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const dataTables = reactive({ value: [] }); // data bảng
let loading = ref(true); // loading bảng
const pageSize4 = ref(20);
const checkTinh = ref(true);
const listType = ref(MethodService.copyObject(DataService).listCommune);
let checkSearchProvince = ref(false);
const lang_web = langWeb();
import { useI18n } from "vue-i18n";
import { langWeb } from "../../stores/lang";
const { t } = useI18n();
// function
// lấy về danh sách xã
const fn_LayDanhSachXa = async () => {
  let dataFilter = {
    filters: tableRules.filters,
    fields: tableRules.fields,
    limit: tableRules.limit,
    offset: tableRules.offset,
    sort: tableRules.sort,
    showFormSearch: tableRules.showFormSearch,
  };
  try {
    const response = await mushroom.commune.listAsync(dataFilter, {
      cacheAge: 1,
    });
    if (response?.result) {
      let res = changeData(response.result);
      dataTables.value = res;
      tableRules.total = response.meta.total;
      tableRules.page = response.meta.offset / response.meta.limit + 1;
      loading.value = false;
    }
  } catch (e) {
    MethodService.showError(e.code);
  }
};

const changeData = (data) => {
  data.forEach((element) => {
    element.fullName = "";
    element.kinhDo = "";
    element.viDo = "";

    element.fullName = element.type + " " + element.name;
    element.kinhDo = element.center.lng;
    element.viDo = element.center.lat;
  });
  return data;
};

// hiển thị form tìm kiếm
const fn_showFormTimKiem = () => {
  tableRules.showFormSearch = !tableRules.showFormSearch;
};
// tìm kiếm
const fn_TimKiem = (formEl) => {
  if (!formEl) return;
  formEl.validate(async (valid) => {
    if (valid) {
      tableRules.dataSearch.value["code"] = tableRules.dataSearch.value["code"]
        ? tableRules.dataSearch.value["code"].trim()
        : "";
      tableRules.dataSearch.value["type"] = tableRules.dataSearch.value["type"]
        ? tableRules.dataSearch.value["type"].trim()
        : "";
      tableRules.dataSearch.value["name"] = tableRules.dataSearch.value["name"]
        ? tableRules.dataSearch.value["name"].trim()
        : "";

      // handleProvince();
      handleDistrict();

      if (
        tableRules.dataSearch.value["districtId"] === "" &&
        dvhc.districtDataArray.length > 0
      ) {
        const listIdDistrict = dvhc.districtDataArray.map((x) => x.id);
        tableRules.dataSearch.value["districtId"] =
          tableRules.dataSearch.value["districtId"] +
          "in:" +
          listIdDistrict.join(",");
        console.log(
          "111111111111111: ",
          tableRules.dataSearch.value["districtId"] +
            "in:" +
            listIdDistrict.join(",")
        );
        checkSearchProvince.value = true;
      } else {
        checkSearchProvince.value = false;
      }

      tableRules.filters = MethodService.filterTable(
        JSON.stringify(tableRules.dataSearch)
      );
      if (checkSearchProvince.value) {
        tableRules.filters = tableRules.filters.replace("=", ":");
      }

      console.log("bbbbbbbbbbb: ", tableRules.filters);
      tableRules.offset = 0;
      tableRules.sort = "";
      fn_LayDanhSachXa();
      if (checkSearchProvince.value) {
        tableRules.dataSearch.value["districtId"] = "";
      }
    } else return false;
  });
};
// phân trang
const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  fn_tableChangeOffset(tableRules.page);
};
const fn_tableCurentChange = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tablePrevClick = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tableNextClick = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tableChangeOffset = (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * tableRules.limit;
  fn_LayDanhSachXa();
};
const fn_tableSortChange = (column, tableSort) => {
  tableSort = tableRules;
  MethodService.tableSortChange(column, tableSort);
  fn_LayDanhSachXa();
};

const filtersDistrict = async () => {
  const idDistrict = localStorage.getItem("idDistrict");
  if (idDistrict) {
    await dvhc.getProvinceById(idDistrict);
  }
  if (idDistrict && dvhc.idProvince) {
    localStorage.removeItem("idDistrict");
    valueHuyen.value = idDistrict;
    valueTinh.value = dvhc.idProvince;
    checkTinh.value = false;
    tableRules.dataSearch.value["districtId"] = idDistrict;
    tableRules.filters = MethodService.filterTable(
      JSON.stringify(tableRules.dataSearch)
    );
    tableRules.offset = 0;
    tableRules.sort = "";
    fn_LayDanhSachXa();
  } else {
    tableRules.filters = "";
  }
};

const handleDistrict = (event) => {
  if (!event) {
    return;
  }

  tableRules.dataSearch.value["districtId"] = tableRules.dataSearch.value[
    "districtId"
  ]
    ? tableRules.dataSearch.value["districtId"].trim()
    : "";
};

const handleProvince = async (event) => {
  tableRules.dataSearch.value["districtId"] = "";
  if (!event) {
    checkTinh.value = true;
    return;
  }

  if (valueTinh.value) {
    checkTinh.value = false;
  } else {
    checkTinh.value = true;
  }
  valueHuyen.value = "";
  dvhc.tableRulesDistrict.filters = `provinceId=${event}`;
  await dvhc.getDistrict();
};

const getProvinceType = (provinceType) => {
  return t(`t_${provinceType.toLowerCase().split(" ").join("_")}`);
};

watch(
  () => lang_web.showLang,
  () => {
    listType.value = MethodService.copyObject(DataService).listCommune;
  }
);

onMounted(async () => {
  await dvhc.getProvince();
  await dvhc.getDistrict();
  filtersDistrict();
  fn_LayDanhSachXa();
});
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-header">
        <h3 class="text-uppercase mb-0">{{ $t("t-dvhc-xa-hoa") }}</h3>
        <div class="text-end">
          <div class="btn-header">
            <button
              class="btn btn-soft-secondary btn-border"
              @click="fn_showFormTimKiem()"
            >
              <i class="ri-search-line"></i>
              {{ $t("t-search") }}
            </button>
          </div>
        </div>
      </div>

      <div class="card-body pt-0">
        <div class="main-body">
          <div class="main-body-search mx-3 mb-3">
            <b-collapse
              v-model="tableRules.showFormSearch"
              id="myFormSearch"
              class="b-shadow"
            >
              <b-card>
                <el-form
                  ref="ruleFormRef"
                  :model="tableRules.dataSearch.value"
                  :rules="validForm.value"
                  label-position="top"
                  @submit.prevent
                >
                  <b-row>
                    <b-col md="6">
                      <el-form-item :label="$t('t-province')">
                        <el-select
                          v-model="valueTinh"
                          filterable
                          clearable
                          :placeholder="$t('t-choose-province')"
                          :no-match-text="$t('t-match-data')"
                          :no-data-text="$t('t-no-data')"
                          @change="handleProvince"
                        >
                          <el-option
                            v-for="item in dvhc.provinceData"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>
                    <b-col md="6">
                      <el-form-item :label="$t('t-huyen')">
                        <el-select
                          :disabled="checkTinh"
                          v-model="tableRules.dataSearch.value['districtId']"
                          filterable
                          clearable
                          :placeholder="$t('t-choose-huyen')"
                          :no-match-text="$t('t-match-data')"
                          :no-data-text="$t('t-no-data')"
                          @change="handleDistrict"
                        >
                          <el-option
                            v-for="item in dvhc.districtDataArray"
                            :key="item.id"
                            :label="item.type + ' ' + item.name"
                            :value="item.id"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>
                    <b-col>
                      <el-form-item :label="$t('t-code-commune')" prop="code">
                        <el-input
                          clearable
                          v-model="tableRules.dataSearch.value['code']"
                          :placeholder="$t('t-place-code-commune')"
                          type="number"
                        ></el-input>
                      </el-form-item>
                    </b-col>
                    <b-col>
                      <el-form-item :label="$t('t-type-commune')" prop="type">
                        <el-select
                          v-model="tableRules.dataSearch.value['type']"
                          filterable
                          clearable
                          :placeholder="$t('t-place-type-commune')"
                          :no-match-text="$t('t-match-data')"
                          :no-data-text="$t('t-no-data')"
                        >
                          <el-option
                            v-for="item in listType"
                            :key="item.label"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>
                    <b-col>
                      <el-form-item :label="$t('t-name-commune')" prop="name">
                        <el-input
                          clearable
                          v-model="tableRules.dataSearch.value['name']"
                          :placeholder="$t('t-input-name-commune')"
                        ></el-input>
                      </el-form-item>
                    </b-col>
                  </b-row>
                  <div class="text-center">
                    <a
                      class="btn btn-soft-secondary btn-border mx-3"
                      style="width: 80px"
                      @click="fn_showFormTimKiem()"
                    >
                      {{ $t("t-hide") }}
                    </a>
                    <button
                      class="btn btn-secondary bg-gradient btn-border waves-effect waves-light"
                      style="width: 100px"
                      native-type="submit"
                      @click="fn_TimKiem(ruleFormRef)"
                    >
                      {{ $t("t-search") }}
                    </button>
                  </div>
                </el-form>
              </b-card>
            </b-collapse>
          </div>
          <div class="mb-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:page-size="pageSize4"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
          <el-table
            size="small"
            border
            stripe
            :data="dataTables.value"
            style="width: 100%"
            v-loading="loading"
            :empty-text="$t('t-no-data')"
            @sort-change="fn_tableSortChange"
            :default-sort="{
              prop: tableRules.defaultSort[0],
              order: tableRules.defaultSort[1],
            }"
          >
            <el-table-column
              prop="code"
              :label="$t('t-code-commune')"
              align="center"
              width="100"
              :sortable="true"
            ></el-table-column>
            <el-table-column
              prop="type"
              :label="$t('t-type-commune')"
              width="120"
              align="center"
              :sortable="true"
              sort-by="Phường"
            >
              <template #default="scope">
                {{ getProvinceType(scope.row.type) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              :label="$t('t-name-commune')"
              align="center"
              :sortable="true"
            ></el-table-column>
          </el-table>
          <div class="mt-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:page-size="pageSize4"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.el-table {
  height: calc(100vh - 280px);
}
</style>
